import "./global.scss";
import "./polyfills";
import { defineCustomElements } from "@ionic/pwa-elements/loader";
import ReactDOM from "react-dom";

import App from "./app/app";

ReactDOM.render(<App />, document.getElementById("root"));

defineCustomElements(window);
