import { getAuthHeader } from "@src/app/superagent";
import { environmentConfig } from "@src/appV2/environment";
import request from "superagent";

export const getFSSProfilePicDistributionURL = async (workerId: string): Promise<string> => {
  const distributionURL = await request
    .get(
      `${environmentConfig.REACT_APP_BASE_API_URL}/agentProfile/${workerId}/profile-image-distribution-url`
    )
    .set(await getAuthHeader());
  return distributionURL.body?.distribution_url;
};
