import type { Worker } from "@src/appV2/Worker/api/types";
import { Agent, Shift } from "@src/lib/interface/src";

export const makeInstantpayLogParameters = (
  shift: Shift,
  isInstantPay: boolean | undefined,
  agent: Agent | Worker | undefined,
  areBreaksMandatory?: boolean
) => {
  const realAgent = agent || shift.agent;
  return {
    shiftId: shift._id,
    HCFId: shift?.facility?.userId,
    HCFname: shift.facility?.name,
    HCFMSA: shift.facility?.fullAddress?.metropolitanStatisticalArea,
    HCPid: realAgent?.userId ?? shift.agentId,
    HCPname: realAgent?.name,
    HCPqualification: realAgent?.qualification ?? shift.agentReq,
    instant_pay: isInstantPay ?? false,
    isInstantPay: isInstantPay ?? false,
    workplaceId: shift?.facilityId,
    areBreaksMandatory: Boolean(areBreaksMandatory),
  };
};
