import { type FacilityNote } from "@src/appV2/Facilities/types";
import { isEmpty, keyBy } from "lodash";

import { FacilityNoteIdentifier } from "../constants/facility";

const getNotesByCategory = (notes, key) => {
  if (isEmpty(notes)) {
    return "";
  }
  const obj = keyBy(notes, "identifier");
  return (obj && obj[key]) || "";
};

const getCheckInInstructionsObj = (facilityNotes: FacilityNote[]) => {
  return {
    CHECK_IN_INST: getNotesByCategory(facilityNotes, FacilityNoteIdentifier.CHECK_IN_INST),
    CHECK_IN_INST_PARKING: getNotesByCategory(
      facilityNotes,
      FacilityNoteIdentifier.CHECK_IN_INST_PARKING
    ),
    CHECK_IN_INST_ENTRANCE: getNotesByCategory(
      facilityNotes,
      FacilityNoteIdentifier.CHECK_IN_INST_ENTRANCE
    ),
    CHECK_IN_INST_ORIENTATION: getNotesByCategory(
      facilityNotes,
      FacilityNoteIdentifier.CHECK_IN_INST_ORIENTATION
    ),
    CHECK_IN_INST_EARLYCHECKIN: getNotesByCategory(
      facilityNotes,
      FacilityNoteIdentifier.CHECK_IN_INST_EARLYCHECKIN
    ),
    CHECK_IN_INST_FIRSTSHIFT: getNotesByCategory(
      facilityNotes,
      FacilityNoteIdentifier.CHECK_IN_INST_FIRSTSHIFT
    ),
    CHECK_IN_INST_TIMECARD: getNotesByCategory(
      facilityNotes,
      FacilityNoteIdentifier.CHECK_IN_INST_TIMECARD
    ),
    CHECK_IN_INST_DRESS: getNotesByCategory(
      facilityNotes,
      FacilityNoteIdentifier.CHECK_IN_INST_DRESS
    ),
    CHECK_IN_INST_OTHER: getNotesByCategory(
      facilityNotes,
      FacilityNoteIdentifier.CHECK_IN_INST_OTHER
    ),
  };
};

export { getNotesByCategory, getCheckInInstructionsObj };
