/**
 * Given money in US dollars, format as dollars string.
 * For example, when given the number `52.341`, return `'$52.34'`
 * @param amountInDollars a number
 * @returns A string representing the amount in USD
 */
export function formatDollarsAsUsd(
  amountInDollars: number,
  options?: Intl.NumberFormatOptions
): string {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    ...options,
  }).format(amountInDollars);
}

/**
 * Given money in US cents, format as dollars string.
 * For example, when given the number `52`, return `'$0.52'`
 * @param amountInCents a number
 * @returns A string representing the amount in USD
 */
export function formatCentsAsUsd(amountInCents: number): string {
  return formatDollarsAsUsd(amountInCents / 100);
}
