// Refer promises' sequence head
const promises = new Map<string, Promise<any>>();

/**
 * @deprecated
 * Do not use this function. Use a standard for loop.
 * This uses a shared object, and is quite dangerous.
 * Throttle promises
 *
 * @param sequenceName - name of sequence (ex, name of function making an API call)
 * @param nextPromise - promise to be put in sequence
 * @returns awaitable, which returns same result as nextPromise, but after fulfilling
 * whole sequence of previous promises.
 */
export function promiseInSequence<T>(
  sequenceName: string,
  nextPromise: Promise<T> | (() => Promise<T>)
): Promise<T> {
  const prevPromise = promises.get(sequenceName);
  const next = nextPromise instanceof Function ? nextPromise : () => nextPromise;
  const result = prevPromise ? prevPromise.then(next, next) : next();
  promises.set(sequenceName, Promise.resolve(result));
  return result;
}
