import { environmentConfig } from "@src/appV2/environment";
import { getStripe } from "@src/appV2/Stripe";
import {
  DEFAULT_ERROR_MESSAGE,
  STRIPE_CONSENT_DECLINED,
  STRIPE_ERROR_MESSAGE,
  STRIPE_UPLOAD_CANCELLED,
} from "@src/constants/stripeDocumentAutoVerification.constants";
import { getFirebaseSingleton } from "@src/lib/firebase";

export interface IVerificationSession {
  id: string;
  verificationSessionId: string;
  clientSecret: string;
  ephemeralKeySecret?: string;
}

export const getVerificationSessionClientSecret = async (
  hcpId: string,
  documentId: string
): Promise<{ verificationSession?: IVerificationSession; error?: string }> => {
  try {
    const token =
      (await getFirebaseSingleton().currentUser?.getIdToken()) || localStorage.getItem("authToken");
    if (!token) {
      throw new Error(DEFAULT_ERROR_MESSAGE);
    }
    const headers: HeadersInit = new Headers();
    headers.set("Content-Type", "application/json");
    headers.set("Authorization", token);

    // Create the VerificationSession in IdentityDocAutoVerification Service.
    const response: Response | undefined = await fetch(
      `${environmentConfig.REACT_APP_IDENTITY_DOC_AUTOVERIFICATION_SERVICE_API_URL}/v1/workers/${hcpId}/stripe/verification-sessions`,
      {
        method: "POST",
        body: JSON.stringify({
          documentId,
        }),
        headers,
      }
    );

    if (response?.status !== 201) {
      throw new Error(STRIPE_ERROR_MESSAGE);
    }

    const session = await response.json();
    return {
      verificationSession: {
        id: session.id,
        verificationSessionId: session.verificationSessionId,
        clientSecret: session.clientSecret,
        ephemeralKeySecret: session.ephemeralKeySecret,
      },
    };
  } catch (error) {
    return { error: error.message };
  }
};

export const loadStripeModal = async (clientSecret: string): Promise<void> => {
  const stripe = await getStripe();
  if (!stripe) {
    throw new Error(STRIPE_ERROR_MESSAGE);
  }
  const { error } = await stripe.verifyIdentity(clientSecret);
  if (error) {
    // check if session was cancelled by the user.
    // otherwise check for any other error message from stripe.
    let errorMsg = error?.message ?? DEFAULT_ERROR_MESSAGE;
    switch (error?.code) {
      case "session_cancelled":
        errorMsg = STRIPE_UPLOAD_CANCELLED;
        break;
      case "consent_declined":
        errorMsg = STRIPE_CONSENT_DECLINED;
        break;
    }

    throw new Error(errorMsg);
  }
};
