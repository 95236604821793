import { Facility } from "@src/lib/interface";
import { reduce } from "lodash";

/**
 * @deprecated
 * Replace with sumBy(openShifts, "total") and test
 * Move this inline to the one place it is used.
 */
export function computeShiftsCountCalendar(
  openShifts: Record<string, { total: number }> | undefined
): number {
  return reduce(openShifts, (acc, { total }) => acc + total, 0);
}

/**
 * @deprecated
 * Replace with sumBy(facilityShifts, "shiftsCount") and test
 * Move this inline to the one place it is used.
 */
export function computeShiftsCountMap(facilityShifts: Facility[]): number {
  return reduce(facilityShifts, (acc, { shiftsCount }) => acc + (shiftsCount ?? 0), 0);
}
