import { OnboardingErrorMessages } from "@src/constants/loginErrorConstants";

class FirebaseUpdateError extends Error {
  private _errorCode: string;
  constructor(error: string) {
    super(OnboardingErrorMessages[error as keyof typeof OnboardingErrorMessages]);
    this._errorCode = error;
  }
  public get errorCode() {
    return this._errorCode;
  }
}

export { FirebaseUpdateError };
