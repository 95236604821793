import type { Worker } from "@src/appV2/Worker/api/types";
import { uniq } from "lodash";

export const getWorkerQualifications = (worker: Worker): string[] => {
  if (!worker?.licensesData?.length) {
    return [];
  }
  const qualifications: string[] = [];
  for (const data of worker.licensesData) {
    qualifications.push(data.qualification);
  }
  return uniq(qualifications);
};
