import { formatTime } from "@clipboard-health/date-time";
import { DEFAULT_TIMEZONE } from "@src/app/attendancePolicy/constants";
import { ShiftGeofence } from "@src/lib/interface/src";
import { subMinutes } from "date-fns";
import moment from "moment-timezone";

import {
  allowedClockInDifferenceInMinutes,
  allowedClockOutDifferenceInMinutes,
} from "../constants/shiftEventTimeDifference";
import {
  breakEndRadarError,
  breakStartRadarError,
  clockInAfterShiftEndError,
  clockInBeforeOneHourError,
  clockInEmpty,
  clockInOutEmpty,
  clockInRadarError,
  clockOutAfterTwoHoursError,
  clockOutBeforeClockIn,
  clockOutBeforeShiftStart,
  clockOutEmpty,
  clockOutPostActualClockOut,
  earlyClockInIsDisabledError,
  eventTimeNotInOrder,
  lunchInBeforeLunchOut,
  lunchInOutEmpty,
} from "../constants/shiftEventTimeErrorMessages";

const GRACE_ENTRY_TIME_IN_MIN = 5;

interface ILunchInTime {
  time?: string;
  lunchOutTime?: string;
  geofence?: ShiftGeofence;
  timezone?: string;
}

interface ILunchOutTime {
  time?: string;
  geofence?: ShiftGeofence;
  timezone?: string;
}

interface IClockInTime {
  time: string;
  shiftStart: string;
  shiftEnd: string;
  isEarlyClockInEnabled: boolean;
  geofence?: ShiftGeofence;
  timezone?: string;
}

interface IClockOutTime {
  time: string;
  shiftStart?: string;
  actualClockOutTime?: string;
  shiftEnd?: string;
}

export const getClockInTimeError = ({
  time,
  shiftStart,
  shiftEnd,
  isEarlyClockInEnabled,
  geofence,
  timezone = DEFAULT_TIMEZONE,
}: IClockInTime): string => {
  if (
    geofence?.entry &&
    moment(time).add(GRACE_ENTRY_TIME_IN_MIN, "minutes").isBefore(moment(geofence.entry))
  ) {
    const acceptedTime = formatTime(subMinutes(new Date(geofence.entry), GRACE_ENTRY_TIME_IN_MIN), {
      timeZone: timezone,
    });
    return clockInRadarError.replace(/%s/g, acceptedTime);
  }
  if (!isEarlyClockInEnabled && moment(time).isBefore(moment(shiftStart))) {
    return earlyClockInIsDisabledError;
  }
  if (moment(shiftStart).diff(moment(time), "minutes") > allowedClockInDifferenceInMinutes) {
    return clockInBeforeOneHourError;
  }
  if (moment(shiftEnd).diff(moment(time), "minutes") < 0) {
    return clockInAfterShiftEndError;
  }
  return "";
};

export const getClockOutTimeError = ({
  time,
  shiftStart,
  actualClockOutTime,
  shiftEnd,
}: IClockOutTime): string => {
  if (moment(time).diff(moment(shiftEnd), "minutes") > allowedClockOutDifferenceInMinutes) {
    return clockOutAfterTwoHoursError;
  }
  if (moment(time).isAfter(moment(actualClockOutTime))) {
    return clockOutPostActualClockOut;
  }
  if (moment(time).isBefore(moment(shiftStart))) {
    return clockOutBeforeShiftStart;
  }
  return "";
};

export const getLunchInTimeError = ({
  time,
  lunchOutTime,
  geofence,
  timezone = DEFAULT_TIMEZONE,
}: ILunchInTime): string => {
  if (!time) {
    return "";
  }
  if (
    geofence?.endBreak &&
    moment(time).add(GRACE_ENTRY_TIME_IN_MIN, "minutes").isBefore(moment(geofence.endBreak))
  ) {
    const acceptedTime = formatTime(
      subMinutes(new Date(geofence.endBreak), GRACE_ENTRY_TIME_IN_MIN),
      { timeZone: timezone }
    );
    return breakEndRadarError.replace(/%s/g, acceptedTime);
  }
  if (lunchOutTime && moment(time).isBefore(moment(lunchOutTime))) {
    return lunchInBeforeLunchOut;
  }
  return "";
};

export const getLunchOutTimeError = ({
  time,
  geofence,
  timezone = DEFAULT_TIMEZONE,
}: ILunchOutTime): string => {
  if (!time) {
    return "";
  }
  if (geofence?.startBreak && moment(time).isAfter(moment(geofence.startBreak))) {
    const acceptedTime = formatTime(new Date(geofence.startBreak), {
      timeZone: timezone,
    });
    return breakStartRadarError.replace(/%s/g, acceptedTime);
  }
  return "";
};

export const validateShiftTime = (clockInTime, clockOutTime, lunchOutTime, lunchInTime): string => {
  const errorMessage = validateShiftTimesFilled(
    clockInTime,
    clockOutTime,
    lunchOutTime,
    lunchInTime
  );
  if (errorMessage) {
    return errorMessage;
  }
  //check when break is skipped clock-in and clock-out are in order
  if (!lunchOutTime && !lunchInTime && clockInTime && clockOutTime) {
    if (moment(clockInTime).isAfter(moment(clockOutTime))) {
      return clockOutBeforeClockIn;
    }
  }
  //check event times are in order - clock-in<lunch-in<lunch-out<clock-out
  if (lunchOutTime && lunchInTime && clockInTime && clockOutTime) {
    if (
      moment(lunchInTime).isAfter(moment(clockOutTime)) ||
      moment(lunchOutTime).isAfter(moment(lunchInTime)) ||
      moment(clockInTime).isAfter(moment(lunchOutTime)) ||
      moment(clockInTime).isAfter(moment(clockOutTime)) ||
      moment(lunchOutTime).isAfter(moment(clockOutTime)) ||
      moment(lunchOutTime).isAfter(moment(lunchInTime))
    ) {
      return eventTimeNotInOrder;
    }
  }
  return "";
};

const validateShiftTimesFilled = (clockInTime, clockOutTime, lunchOutTime, lunchInTime): string => {
  //Check if clock-in is filled and clock-out is not
  if (!clockInTime && clockOutTime) {
    return clockInEmpty;
  }
  //Check if clock-out is filled and clock-in is not
  if (clockInTime && !clockOutTime) {
    return clockOutEmpty;
  }
  //Check if lunch-in and lunch-out are filled and clock-in and clock-out are not
  if ((!clockInTime || !clockOutTime) && (lunchOutTime || lunchInTime)) {
    return clockInOutEmpty;
  }
  //Check if lunch-in is filled and lunch-out is not or vice versa
  if ((!lunchOutTime && lunchInTime) || (!lunchInTime && lunchOutTime)) {
    return lunchInOutEmpty;
  }
  return "";
};
