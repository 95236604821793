import * as braze from "@braze/web-sdk";

enum REFERENCE_ANALYTICS_ATTRIBUTES {
  UI_VARIANT = "References UI Variant",
  STATUS = "Reference Requirement Status",
}

export const logReferenceStatus = (userId: string, status: string, variant: string) => {
  const brazeUser = braze.getUser();
  brazeUser?.setCustomUserAttribute(REFERENCE_ANALYTICS_ATTRIBUTES.UI_VARIANT, variant);
  brazeUser?.setCustomUserAttribute(REFERENCE_ANALYTICS_ATTRIBUTES.STATUS, status);
};
