/*
 * Polyfill stable language features.
 * It's recommended to use @babel/preset-env and browserslist
 * to only include the polyfills necessary for the target browsers.
 */
import "core-js/stable";
import "core-js/es/object/has-own";
import "regenerator-runtime/runtime";

// @clipboard/utils require this polyfill to run w/o Node
// Mongoose require TextEncoder
// config as per main repo's frontend-hcpmobile/polyfill
if (process.env.NODE_ENV === "test" && typeof window["TextEncoder"] !== "function") {
  const TextEncodingPolyfill = require("text-encoding");
  (window as any).TextEncoder = TextEncodingPolyfill.TextEncoder;
  (window as any).TextDecoder = TextEncodingPolyfill.TextDecoder;
}
/**
 * FIXME Remove these global.crypto and `window.crypto` hacks.
 * https://linear.app/clipboardhealth/issue/FEF-155/remove-windowcrypto-and-directly-use-uuid-package
 */
if (!global.crypto) {
  global.crypto = {} as Crypto;
}
if (!window.crypto?.randomUUID) {
  window.crypto.randomUUID = require("uuid").v4;
}
/**
 * This file contains polyfills loaded on all browsers
 **/
